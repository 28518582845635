import React, { useEffect, useRef, useState } from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import styles from "./skillzgames.module.css"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const { format } = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
})

const stringComparer = prop => (a, b) => {
  if (a[prop] === null) {
    return 1
  }

  if (b[prop] === null) {
    return -1
  }

  var A = a[prop].toUpperCase()
  var B = b[prop].toUpperCase()

  if (A < B) {
    return -1
  }
  if (A > B) {
    return 1
  }

  return 0
}

const integerComparer = prop => (a, b) => {
  if (a[prop] === null) {
    return 1
  }

  if (b[prop] === null) {
    return -1
  }

  return a[prop] - b[prop]
}

function ToggleDisplay(props) {
  return (
    <span
      style={{
        display: "inline-block",
        marginRight: 5,
        height: 15,
        width: 15,
        borderRadius: 15,
        backgroundColor: props.enabled ? "green" : "white",
      }}
    />
  )
}

const SkillzPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const [sortColumn, setSortColumn] = useState("plRanking")
  const [sortAscending, setSortAscending] = useState(true)
  const [filter, setFilter] = useState([])
  const [submitted, setSubmitted] = useState(false)
  const [headerToggled, setHeaderToggled] = useState(false)
  const smallScreen = useSmallScreen()

  const headerRef = useRef(null)
  const tableBodyRef = useRef(null)

  useEffect(() => {
    document.body.classList.add(styles.gamePage)
    const timeout = setTimeout(() => {
      document.body.classList.add(styles.stopTransition)
    }, 400)
    return () => {
      clearTimeout(timeout)
      document.body.classList.remove(styles.gamePage)
      document.body.classList.remove(styles.stopTransition)
    }
  }, [])

  const list = data.allSkillzgamesJson.nodes

  let listCopy = [...list]
    .filter(game => !game.unavailable)
    .sort(integerComparer("plRanking"))

  if (sortColumn && listCopy[0]) {
    const compare =
      typeof listCopy[0][sortColumn] === "string"
        ? stringComparer(sortColumn)
        : integerComparer(sortColumn)

    listCopy.sort(compare)

    if (!sortAscending) listCopy.reverse()
  }

  const displayList = listCopy.filter(game => {
    if (filter.length > 0) {
      let include = filter.reduce((cur, next) => {
        if (!cur) return false

        return !!game[next]
      }, true)

      return include
    }

    return true
  })

  const toggleSort = (columnName, ascendingSort) => {
    return () => {
      if (sortColumn === columnName) {
        setSortAscending(!sortAscending)
      } else {
        setSortColumn(columnName)
        setSortAscending(ascendingSort)
      }
    }
  }

  const handleSubmit = () => {
    setSubmitted(true)
  }

  const handleToggle = () => {
    setHeaderToggled(!headerToggled)
  }

  const featuredPlayer = "imitating"
  const featuredGameId = 14961
  const featuredGame = list.find(game => game._id === featuredGameId)

  const filterFunction = item => () => {
    if (filter.includes(item)) {
      setFilter(filter.filter(_item => _item !== item))
    } else {
      setFilter([...filter, item])
    }
  }
  return (
    <Layout title={siteTitle}>
      <SEO
        title="Skillz Game List (Almost Complete)"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <div id="list-predecessor" className={styles.list}>
        {/* <div style={{ marginTop: 140 }}>
          <p style={{ textAlign: "center" }}>
            Not feeling the love from Skillz lately, so we are taking a short
            break to reevaluate where we want to invest our time. Hopefully
            we'll see you all again soon.
          </p>
        </div> */}

        <div className={styles.pageHeader}>
          <h3>Skillz Games - the almost complete list</h3>
          <p>
            This list received it's final update on March 22, 2022. It will
            become outdated fairly quickly as that is the nature of the
            internet. We will leave it here for awhile so that those searching
            for older games can find them but we will eventually terminate this
            page as it will quickly become unuseful. We have made this decision
            after realizing that we are unable to be successful on the Skillz
            platform as developers and therefore can no longer dedicate the time
            required to properly maintain this list. Thank you to those of you
            who played our games and participated in helping us keep this list
            up to date for the few years we spent curating it.
          </p>
          <p>
            Ever tried to find your next favorite Skillz game, but found the
            Skillz website lists only a small fraction of the available games
            out there? We know the struggle is real, so we created this site to
            help out. We check monthly to see if we can find new games to add to
            our list, so check back often to discover our latest finds. To learn
            more about this list, check out our
            <Link
              to="/skillzgamesfaqs"
              title="Skillz Game List FAQs"
              style={{ fontWeight: 900 }}
            >
              {" "}
              FAQs{" "}
            </Link>
            page.
          </p>
        </div>
        {/* <div id="featured-box">
          <div
            style={{
          border: "1px solid white",
          borderRadius: 5,
          margin: "10px 0px 20px 0px",
          padding: "10px 20px 10px 20px",
          textAlign: "center",
            }}
          >
            <div
          style={{
          fontWeight: 700,
          margin: "10px 0px 10px 0px",
          }}
            >

          <div style={{ display: "inline-block", flex: "" }}>
          📣{""}A Special Note{""}
          <span
          style={{
          display: "inline-block",
          transform: "scale(-1, 1)",
          }}
          >
          📣
          </span>
          <br /> to our Most Valuable Players{" "}
          </div>

            </div>

            <div style={{ flexDirection: "row", marginBottom: 10 }}>
          <p>
          To those of you who have played our games day in and day out...
          we want you to know that we do notice you and it truly warms our
          heart. It may seem silly, but we check the leaderboards of our
          games every single day and take the time to view the Game Levels
          for those we see frequently. We know it is no easy feat to grow
          that Game Level in a non-monetized game - in fact, it is
          literally x10 harder.
          </p>
          <p>
          For us, seeing the "hustler rush" for those first few days
          following a new game launch is exciting, but small dev teams
          know it never lasts. So to those of you that are of our "Beyond
          the Hustlers" group, <strong>THANK YOU</strong>. So over the
          next several weeks we will be trying to reach out to some of you
          through the direct message feature that is offered in any Skillz
          game that has a chat feature (our giant list below identifes
          those with the 💬 symbol). We will be using this method of
          contact to ensure we are reaching out to the right person. So if
          you see a direct message come through from <em>imitating</em>, I
          may have a little something for you as an extra special thank
          you from our team.

          </p>
            </div>
          </div>
        </div> */}
        {/* <div id="featured-box">
            <div
            style={{
          border: "1px solid white",
          borderRadius: 5,
          margin: "10px 0px 10px 0px",
          padding: "10px 20px 10px 20px",
          textAlign: "center",
            }}
          >
            <p style={{ fontWeight: 700, margin: "10px 0px 0px 0px" }}>
          🎉 Featured Game 🎉
            </p>

            <div
          className={styles.tableCell + " " + styles.featured}
          style={{ marginBottom: 10 }}
            >
          <img
          className={styles.gameIcon}
          src={featuredGame.icon}
          alt={featuredGame.name + " icon"}
          />
          {featuredGame.name}
            </div>
            <div style={{ flexDirection: "row", marginBottom: 10 }}>
          {featuredGame.ios ? (
          <a href={featuredGame.ios} style={{ margin: "5px" }}>
          <Img fixed={data.iconIOS.childImageSharp.fixed} />
          </a>
          ) : (
          ""
          )}
          {featuredGame.android ? (
          <a href={featuredGame.android} style={{ margin: "5px" }}>
          <Img fixed={data.iconAndroid.childImageSharp.fixed} />
          </a>
          ) : (
          ""
          )}
            </div>
            <p style={{ fontSize: 12 }}>Featured Player: {featuredPlayer}</p>
          </div>
        </div> */}
      </div>
      <div id="skillz-list-table">
        {smallScreen && (
          <div id="table-header" className={styles.sticky} ref={headerRef}>
            <div
              onClick={handleToggle}
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#292929",
                padding: "18px 15px 18px 15px",
              }}
            >
              <div
                style={{
                  fontSize: 24,
                  fontWeight: 700,
                  fontFamily: "Helvetica Neue",
                  // backgroundColor: "pink",
                }}
              >
                <img
                  src={require("../../content/assets/toggleArrow24.png")}
                  alt="toggle arrow"
                  style={{
                    marginRight: 5,
                    height: 18.5,
                    verticalAlign: "baseline",
                  }}
                />{" "}
                Sort
              </div>
              <div
                style={{
                  fontSize: 24,
                  fontWeight: 700,
                  fontFamily: "Helvetica Neue",
                }}
              >
                Filter{" "}
                {filter.length > 0 ? (
                  <img
                    src={require("../../content/assets/filtered.png")}
                    alt="unfiltered icon"
                    style={{
                      marginLeft: 5,
                      width: 21,
                      position: "relative",
                      top: 2,
                      verticalAlign: "baseline",
                    }}
                  />
                ) : (
                  <img
                    src={require("../../content/assets/unfiltered.png")}
                    alt="unfiltered icon"
                    style={{
                      marginLeft: 5,
                      width: 21,
                      position: "relative",
                      top: 2,
                      verticalAlign: "baseline",
                    }}
                  />
                )}
              </div>
            </div>
            <div
              className={
                headerToggled
                  ? styles.ssHeaderRows + " " + styles.ssHeaderRowsVisible
                  : styles.ssHeaderRows
              }
            >
              <div>
                <div
                  className={styles.ssHeaderLabel}
                  style={{ color: sortColumn === "name" ? "#51A7E7" : "" }}
                  onClick={toggleSort("name", true)}
                >
                  Name
                </div>
                <div
                  className={styles.ssHeaderLabel}
                  style={{
                    color: sortColumn === "datePublished" ? "#51A7E7" : "",
                  }}
                  onClick={toggleSort("datePublished", false)}
                >
                  Year
                </div>
                <div
                  className={styles.ssHeaderLabel}
                  style={{ color: sortColumn === "plRanking" ? "#51A7E7" : "" }}
                  onClick={toggleSort("plRanking", true)}
                >
                  Default
                </div>
              </div>
              <div style={{ textAlign: "right" }}>
                <div
                  className={styles.ssFilterOption}
                  onClick={filterFunction("ios")}
                >
                  iOS <Circle selected={filter.includes("ios")} />
                </div>
                <div
                  className={styles.ssFilterOption}
                  onClick={filterFunction("android")}
                >
                  Android <Circle selected={filter.includes("android")} />
                </div>
                <div
                  className={styles.ssFilterOption}
                  onClick={filterFunction("cashEnabled")}
                >
                  Cash <Circle selected={filter.includes("cashEnabled")} />
                </div>
              </div>
            </div>
          </div>
        )}

        {!smallScreen && (
          <div
            id="table-header"
            className={
              styles.tableHeader + " " + styles.tableRow + " " + styles.sticky
            }
            ref={headerRef}
          >
            <div
              className={styles.tableCell}
              onClick={toggleSort("name", true)}
            >
              Name
            </div>
            <div
              className={styles.tableCell}
              onClick={() => {
                if (filter.includes("ios")) {
                  setFilter(filter.filter(item => item !== "ios"))
                } else {
                  setFilter([...filter, "ios"])
                }
              }}
            >
              <ToggleDisplay enabled={filter.includes("ios")} />
              iOS
            </div>
            <div
              className={styles.tableCell}
              onClick={() => {
                if (filter.includes("android")) {
                  setFilter(filter.filter(item => item !== "android"))
                } else {
                  setFilter([...filter, "android"])
                }
              }}
            >
              <ToggleDisplay enabled={filter.includes("android")} />
              Android
            </div>
            <div
              className={styles.tableCell}
              onClick={() => {
                if (filter.includes("cashEnabled")) {
                  setFilter(filter.filter(item => item !== "cashEnabled"))
                } else {
                  setFilter([...filter, "cashEnabled"])
                }
              }}
            >
              <ToggleDisplay enabled={filter.includes("cashEnabled")} />
              Cash
            </div>
            <div
              className={styles.tableCell}
              onClick={toggleSort("datePublished", false)}
            >
              Year
            </div>
          </div>
        )}

        <div id="table-body" ref={tableBodyRef}>
          {displayList.map((game, i) => {
            return <TableRow key={game._id} data={data} game={game} index={i} />
          })}
        </div>
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allSkillzgamesJson {
      nodes {
        _id
        icon
        name
        ios
        android
        samsung
        cashEnabled
        contact
        datePublished
        description
        platform
        publisher
        favorite
        chatEnabled
        mcShortName
        vsFriends
        plRanking
        unavailable
      }
    }
    iconAndroid: file(relativePath: { eq: "icon-android.png" }) {
      childImageSharp {
        fixed(width: 102, height: 34) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    iconIOS: file(relativePath: { eq: "icon-ios.png" }) {
      childImageSharp {
        fixed(width: 102, height: 34) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    iconSamsung: file(relativePath: { eq: "icon-samsung.png" }) {
      childImageSharp {
        fixed(width: 102, height: 34) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    developmentHero: file(relativePath: { eq: "development-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <SkillzPage location={props.location} data={data} {...props} />
    )}
  />
)

const Circle = props => {
  const { selected } = props

  return (
    <div
      className={styles.ssHeaderCircle}
      style={{ backgroundColor: selected ? "#51A7E7" : "#fff" }}
    />
  )
}

const useSmallScreen = () => {
  const [smallScreen, setSmallScreen] = useState(null)
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1000px)")

    function handleMediaChange(e) {
      // Check if the media query is true
      if (e.matches) {
        setSmallScreen(false)
      } else {
        setSmallScreen(true)
      }
    }

    const listener = mediaQuery.addListener(handleMediaChange)

    handleMediaChange(mediaQuery)
    return () => {
      mediaQuery.removeListener(listener)
    }
  }, [])

  return smallScreen
}

const TableRow = props => {
  const [gameToggled, setgameToggled] = useState(false)
  const smallScreen = useSmallScreen()

  const toggleGame = () => {
    setgameToggled(!gameToggled)
  }

  const { data, game, index: i } = props
  const dollarColor = game.cashEnabled
    ? "#0E9140"
    : i % 2
    ? "#4e4e4e"
    : "#3e3e3e"

  if (smallScreen === null) {
    return null
  }

  const year =
    format(game.datePublished) === "1983" ? "" : format(game.datePublished)

  if (smallScreen) {
    return (
      <div
        className={
          styles.tableRow + " " + (i % 2 ? styles.evenRow : styles.oddRow)
        }
      >
        <div className={styles.ssGameHeader} onClick={toggleGame}>
          <div>
            <img
              className={styles.gameIcon}
              style={{ margin: 0 }}
              src={game.icon}
              alt={game.name + " icon"}
            />
          </div>
          <div className={styles.ssGameLabel}>
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "flex-end",
                fontSize: 18,
                fontWeight: 700,
              }}
            >
              {game.name}
            </div>
            <div
              style={{
                // alignItems: "flex-end",

                display: "flex",
                flex: 1,
              }}
            >
              {gameToggled && year}
              {!gameToggled && (
                <>
                  <div
                    style={{
                      width: 17,
                      marginRight: 5,
                    }}
                  >
                    <img
                      style={{
                        opacity: game.ios ? 1 : 0.15,
                      }}
                      src={require("../../content/assets/mini-ios.png")}
                    />
                  </div>

                  <div style={{ width: 17 }}>
                    <img
                      style={{
                        opacity: game.android ? 1 : 0.15,
                      }}
                      src={require("../../content/assets/mini-android.png")}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {gameToggled && (
          <div>
            <div className={styles.ssEnabledIcons}>
              <div className={!game.cashEnabled ? styles.ssDisabledIcon : ""}>
                💰
              </div>
              <div
                className={!game.contact ? styles.ssDisabledIcon : ""}
                title={game.contact ? "Contact" : ""}
              >
                <a
                  href={
                    "mailto:" + game.contact + "?subject=Help with " + game.name
                  }
                >
                  ✉️
                </a>
              </div>
              <div
                className={!game.chatEnabled ? styles.ssDisabledIcon : ""}
                title={game.chatEnabled ? "Chat Enabled" : ""}
              >
                💬
              </div>
              <div
                className={!game.vsFriends ? styles.ssDisabledIcon : ""}
                title={game.vsFriends ? "vs Friends" : ""}
              >
                👥
              </div>
            </div>
            <div className={styles.ssGameButtons}>
              {game.ios ? (
                <a href={game.ios}>
                  <Img fixed={data.iconIOS.childImageSharp.fixed} />
                </a>
              ) : (
                ""
              )}
              {game.android ? (
                <a href={game.android}>
                  <Img fixed={data.iconAndroid.childImageSharp.fixed} />
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <div
      className={
        styles.tableRow + " " + (i % 2 ? styles.evenRow : styles.oddRow)
      }
    >
      <div
        className={styles.tableCell + " " + styles.colFirst}
        onClick={toggleGame}
      >
        <span className={styles.numbered}>{i + 1 + "  "}</span>
        <img
          className={styles.gameIcon}
          src={game.icon}
          alt={game.name + " icon"}
        />
        {game.name}
      </div>

      <div
        title={game.chatEnabled ? "Chat Enabled" : ""}
        className={styles.tableCell + " " + styles.enabledIcon}
      >
        {game.chatEnabled ? "💬" : ""}
      </div>
      <div
        title={game.vsFriends ? "vs Friends" : ""}
        className={styles.tableCell + " " + styles.enabledIcon}
      >
        {game.vsFriends ? "👥" : ""}
      </div>
      <div className={styles.tableCell}>
        {game.ios ? (
          <a href={game.ios}>
            <Img fixed={data.iconIOS.childImageSharp.fixed} />
          </a>
        ) : (
          ""
        )}
      </div>
      <div className={styles.tableCell}>
        {game.android ? (
          <a href={game.android}>
            <Img fixed={data.iconAndroid.childImageSharp.fixed} />
          </a>
        ) : (
          ""
        )}
      </div>

      <div
        className={styles.tableCell + " " + styles.cash}
        style={{ color: dollarColor, fontWeight: 700 }}
      >
        $
      </div>

      <div className={styles.tableCell + " " + styles.year}>
        {format(game.datePublished) === "1983"
          ? ""
          : format(game.datePublished)}
      </div>
    </div>
  )
}
